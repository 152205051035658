<template>
  <div class="message" :class="'message-' + messageType">
    <i
      v-if="messageType === 'success' || messageType === 'form-success'"
      class="far fa-thumbs-up"
    ></i>
    <i
      v-else-if="messageType === 'error' || messageType === 'form-error'"
      class="far fa-thumbs-down"
    ></i>
    <p>{{ message }}</p>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      required: true,
    },
    messageType: {
      type: String,
      default: 'success',
    },
  },
};
</script>
